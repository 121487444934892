<template>
  <div class="scrollstyle"
    @click="clickBody">
    <heads :search-visible="searchVisible"
      @search="search" />
    <div class="tac">
      <div class="menuStyle">
        <el-tree :data="tabList"
          :props="defaultProps"
          :default-expanded-keys="defaultOpeneds"
          :render-content="renderContent"
          :current-node-key="currentData.id"
          :highlight-current="true"
          accordion
          icon-class="el-icon-arrow-right"
          auto-expand-parent
          node-key="id" />
      </div>
      <div class="contentStyle">
        <div>
          <div id="editor"
            class="descr1 vp" />
        </div>
        <div class="help-line" />
        <div class="prevNext">
          <div><span v-if="prevData.name ? true : false">上一篇：</span><span>
              <router-link :to="{name: 'help', params: { classId: prevData.id }}"
                style="color: #1890ff;">{{prevData.name || ''}}</router-link>
            </span></div>
          <div><span v-if="nextData.name ? true : false">下一篇：</span><span>
              <router-link :to="{name: 'help', params: { classId: nextData.id }}"
                style="color: #1890ff;">{{nextData.name || ''}}</router-link>
            </span></div>
        </div>
      </div>
    </div>
    <foots />
  </div>
</template>
<script>
import _ from 'lodash';
import { getTree } from 'util/ui';
import heads from './common/head';
import foots from './common/foot';
import { classifyGet, articleyGet } from './api.js';

export default {
  components: {
    heads,
    foots,
  },
  data() {
    const { isType = '1' } = this.$route.query;
    return {
      // 菜单列表
      tabList: [],
      // 请求的数据列表
      tableData: [],
      // 默认打开的菜单
      defaultOpeneds: [],
      // 当前的内容
      currentData: {},
      // 上一篇
      prevData: {},
      // 下一篇
      nextData: {},
      searchVisible: 1,
      defaultProps: {
        children: 'children',
        label: 'label',
      },
      // 是常见问题，还是用户手册
      isType,
    };
  },
  watch: {
    '$route.params': {
      handler(val) {
        const { classId = '' } = val;
        this.checkeds({ id: classId });
      },
      deep: true,
    },
  },
  created() {
    // 根据当前激活id找出对应的对象
    this.getTabList();
  },
  methods: {
    /* 用router-link跳转 */
    renderContent(h, { node, data }) {
      if (data.children) {
        return (
          <span class="custom-tree-node">
            <span class="menuColor">{node.label}</span>
          </span>
        );
      }
      return (
        <span class="custom-tree-node">
          <span>
            <router-link class="menuColor" to={{ name: 'help', params: { classId: data.id }, query: { isType: this.isType } }}>
              {node.label}
            </router-link>
          </span>
        </span>
      );
    },
    /* 选中时刷新路由 */
    checkeds(val = {}) {
      if (_.find(this.tableData, { pid: val.id })) {
        return false;
      }
      this.checks(val);
    },
    getTabList() {
      // 先获取左侧菜单
      classifyGet({ groupKey: this.isType }).then(({ data }) => {
        const { data: tableData } = data;
        this.tableData = tableData;
        this.tabList = getTree(tableData) || [];
        const { classId = '' } = this.$route.params || {};
        // 如果id不存在,默认第一个的最后一级
        if (classId) {
          this.currentData = _.find(tableData, { id: classId }) || {};
        } else {
          this.getLastChild(_.head(this.tabList));
        }
        // 默认打开的菜单
        this.defaultOpeneds.push(this.currentData.pid || '');
        this.checks(this.currentData);
      });
    },
    /* 选中的菜单 */
    checks(val = {}) {
      if (_.find(this.tableData, { pid: val.id })) {
        return false;
      }
      this.currentData = { ...val };
      // 获取文章内容
      articleyGet({ classId: this.currentData.id || '', status: '1' }).then(({ data, err }) => {
        if (!err) {
          const { data: currentList } = data;
          const contentType = (_.head(currentList) || {}).contentType || '3';
          this.currentData = {
            ...this.currentData,
            content: (_.head(currentList) || {}).content || '',
            contentType,
          };
          // 判断类型contentType:1外链,2关联,3内容
          if (contentType === '3') {
            const editor = document.querySelector('#editor');
            editor.innerHTML = this.currentData.content || '暂无内容';
          } else if (contentType === '2') {
            const classId = _.last(_.split(this.currentData.content, '/'));
            articleyGet({ classId, status: '1' }).then(({ data }) => {
              const { data: inList } = data;
              const content = (_.head(inList) || {}).content || '';
              this.currentData = {
                id: classId,
                content,
                contentType: '2',
              };
              const editor = document.querySelector('#editor');
              editor.innerHTML = this.currentData.content || '暂无内容';
            });
          } else if (contentType === '1') {
            // window.open(this.currentData.content, '_blank');
          }
        }
      });
      const { pid } = _.find(this.tableData, { id: val.id }) || {};
      // 找到当前选择的所有同级
      const fatherData = _.find(this.tableData, { id: pid }) || {};
      const children = _.filter(this.tableData, v => v.pid === fatherData.id && !_.find(this.tableData, { pid: v.id }));
      const key = _.parseInt(_.findKey(children, { id: this.currentData.id })) || 0;
      if (children.length === 1) {
        this.prevData = {};
        this.nextData = {};
      } else if (children.length > 1) {
        if (children.length === key + 1) {
          // 当前是最后一个,则只有上一篇
          this.prevData = children[key - 1];
          this.nextData = {};
        } else if (key === 0) {
          // 当前是第一个,则只有下一篇
          this.prevData = {};
          this.nextData = children[key + 1];
        } else {
          this.prevData = children[key - 1];
          this.nextData = children[key + 1];
        }
      }
    },
    /* 获取最后一级 */
    getLastChild(data = {}) {
      if (data.children) {
        this.getLastChild(_.head(data.children));
      } else {
        this.currentData = { ...data };
      }
    },
    /* 根据搜索结果展示内容 */
    search(val) {
      this.checkeds({ id: val.classId });
    },
    /* 点击关闭搜索结果 */
    clickBody() {
      this.searchVisible++;
    },
  },
};
</script>
<style>
.el-tree-node__content {
  height: 40px;
}
.el-tree-node__content:hover {
  color: #1890ff;
}
.menuColor {
  color: #000;
  width: 90px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
}
/*滚动条样式*/
body::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 0px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 4px;
}
body::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 0px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
</style>

<style lang="less" scoped>
.tac {
  width: 1200px;
  margin: auto;
  margin-top: 149px;
  margin-bottom: 20px;
  min-height: 800px;
}
.menuStyle {
  min-width: 150px;
  width: 13%;
  float: left;
  font-size: 14px;
  border-right: 1px solid #e6e6e6;
  margin-left: 28px;
  height: 800px;
  overflow-x: hidden;
  overflow-y: auto;
}
.contentStyle {
  margin-left: 80px;
  width: 75%;
  height: 800px;
  display: inline-block;
  overflow-x: hidden;
  overflow-y: auto;
}
.title {
  font-size: 18px;
  color: #000;
}
.descr1 {
  width: 75%;
  height: auto;
  line-height: 28px;
  margin: 44px 0px 0px 5px;
  font-size: 14px;
  color: #000;
}

.help-line {
  // width: 900px;
  width: 75%;
  height: 0px;
  border-top: 2px solid #e5e5e5;
  margin-top: 120px;
}
.prevNext {
  height: 44px;
  font-size: 14px;
  color: #1890ff;
  margin: 32px 0px 35px;
}
/*滚动条样式*/
.contentStyle::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 0px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 4px;
}
.contentStyle::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
/*滚动条样式*/
.menuStyle::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 0px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 4px;
}
.menuStyle::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
</style>
