var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scrollstyle", on: { click: _vm.clickBody } },
    [
      _c("heads", {
        attrs: { "search-visible": _vm.searchVisible },
        on: { search: _vm.search }
      }),
      _c("div", { staticClass: "tac" }, [
        _c(
          "div",
          { staticClass: "menuStyle" },
          [
            _c("el-tree", {
              attrs: {
                data: _vm.tabList,
                props: _vm.defaultProps,
                "default-expanded-keys": _vm.defaultOpeneds,
                "render-content": _vm.renderContent,
                "current-node-key": _vm.currentData.id,
                "highlight-current": true,
                accordion: "",
                "icon-class": "el-icon-arrow-right",
                "auto-expand-parent": "",
                "node-key": "id"
              }
            })
          ],
          1
        ),
        _c("div", { staticClass: "contentStyle" }, [
          _vm._m(0),
          _c("div", { staticClass: "help-line" }),
          _c("div", { staticClass: "prevNext" }, [
            _c("div", [
              (_vm.prevData.name
              ? true
              : false)
                ? _c("span", [_vm._v("上一篇：")])
                : _vm._e(),
              _c(
                "span",
                [
                  _c(
                    "router-link",
                    {
                      staticStyle: { color: "#1890ff" },
                      attrs: {
                        to: {
                          name: "help",
                          params: { classId: _vm.prevData.id }
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.prevData.name || ""))]
                  )
                ],
                1
              )
            ]),
            _c("div", [
              (_vm.nextData.name
              ? true
              : false)
                ? _c("span", [_vm._v("下一篇：")])
                : _vm._e(),
              _c(
                "span",
                [
                  _c(
                    "router-link",
                    {
                      staticStyle: { color: "#1890ff" },
                      attrs: {
                        to: {
                          name: "help",
                          params: { classId: _vm.nextData.id }
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.nextData.name || ""))]
                  )
                ],
                1
              )
            ])
          ])
        ])
      ]),
      _c("foots")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "descr1 vp", attrs: { id: "editor" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }